angular.module('app')
    .controller('appController',
        ["$scope", "$timeout", "$window", "$uibModal", "$log", "versionService", function ($scope, $timeout, $window, $uibModal, $log, versionService) {

            var SIXTY_SECONDS = 60000;

            var UPDATE_VERSION_MODAL_TEMPLATE =
                '<div class="modal-body question">A new version is of Ingogo Business is available. Please click update to refresh your browser.</div>' +
                '<div class="modal-footer">' +
                '<button class="btn btn-danger" ng-click="$close()">Update</button>' +
                '</div>';

            (function init() {
                $scope.currentVersion = null;
                poll();
            }());

            function poll() {
                $log.debug('Polling Server for version');

                versionService.shouldUpdate($scope.currentVersion)
                    .then(function (data) {
                        if ($scope.currentVersion === null) {
                            $scope.currentVersion = data.latestVersion;
                        } else if (data.shouldUpdate) {
                            return showUpdateModal();
                        }

                        $timeout(poll, SIXTY_SECONDS);
                    }, function (error) {
                        $timeout(poll, SIXTY_SECONDS * 6);
                    });
            }

            function showUpdateModal() {
                $uibModal.open({
                    keyboard: false,     // prevent dismissal by pressing ESC
                    backdrop: 'static',  // prevent dismissal by clicking backdrop
                    template: UPDATE_VERSION_MODAL_TEMPLATE
                })
                    .result.then(function () {
                        $window.location.reload();
                    });
            }
        }]);
